<gentext-chat-ui-chat
  (managePlanClicked)="managePlan(true)"
  [addOrUpdateMessage$]="addOrUpdateMessage$"
  [suggestions]="chatSuggestions"
  [chatId]="chatId"
  [clearMessages$]="clearMessages$"
  [sendMessage$]="sendMessage$"
  [error$]="error$"
  productName="LexDraft"
  class="flex-1"
></gentext-chat-ui-chat>

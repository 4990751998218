import { Injectable } from '@angular/core';
import { BillingService, LicenseDto } from '@gentext/api-legal-client';
import { LoggingService } from '@gentext/logging';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LicenseService {
  _license$ = new BehaviorSubject<LicenseDto | undefined>(undefined);

  license$ = this._license$.asObservable();
  private _loading = false;
  getLicense() {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        this.logging.trace({
          message: 'license service already loading',
        });
        return;
      }
      this._loading = true;
      this.billingService.billingLicenseGet().subscribe({
        complete: () => {
          this.logging.trace({
            message: 'License get complete',
          });
          this._loading = false;
        },
        next: (res) => {
          this.logging.trace({
            message: 'License get next',
            properties: { res },
          });
          this._loading = false;
          this._license$.next(res);
          resolve(this._license$.value);
        },
        error: (err) => {
          this.logging.exception(err);
          this._loading = false;
          reject();
        },
      });
    });
  }
  constructor(
    private billingService: BillingService,
    private logging: LoggingService,
  ) {}
}
